import { Admin } from "./interfaces";

export const readJWT = () => {
  return localStorage.getItem("token") ?? "";
}

export const storeJWT = (newJWT: string) => {
  localStorage.setItem("token", newJWT);
}

export const deleteJWT = () => {
  localStorage.removeItem("token");
}

export const extractAdminClaims = (jwtString: string): Admin | null => {
  var encodedClaims = jwtString.split(".")[1];
  var claims = window.atob(encodedClaims);
  return JSON.parse(claims.toString());
}