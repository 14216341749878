import { useState, useEffect, useCallback } from "react";
import { Box, Center, CircularProgress, Text, VStack } from "@chakra-ui/react";
import { AxiosError } from "axios";

import Header from "./header/Header";
import DeviceSection from "./deviceSection/DeviceSection";

import { getHomeData, getSemafixConfig } from "../utils/api";
import generateDeviceData from "./generateDeviceData";
import { SemafixConfig, Device, AdminState, ServerStatus } from "../utils/interfaces";


const DEFAULT_CONFIG: SemafixConfig = {
  yellowTimeDays: 0,
  yellowTimeHours: 0,
  yellowTimeMinutes: 10,
  yellowTimeSeconds: 0,

  redTimeDays: 1,
  redTimeHours: 0,
  redTimeMinutes: 0,
  redTimeSeconds: 0,
  
  cyanTimeDays: 0,
  cyanTimeHours: 0,
  cyanTimeMinutes: 10,
  cyanTimeSeconds: 0,

  mailDebugMode: false,
  mailEnableOnConnectionChange: false,
  mailEnableOnAlertChange: false,
  mailRecipients: "",
  useSendGrid: false,
};

interface HomeScreenParams {
  setAdminState: (newState: React.SetStateAction<AdminState>) => void;
}

export default function HomeScreen({ setAdminState }: HomeScreenParams): JSX.Element {
  const [serverStatus, setServerStatus] = useState<ServerStatus>({ unixTime: 0, isLoading: true, hasError: false }); // timestamp a mostrar en Header
  const [allDevices, setAllDevices] = useState<Device[]>([]);
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [config, setConfig] = useState<SemafixConfig>(DEFAULT_CONFIG);

  const [reloadKey, setReloadKey] = useState(0);
  
  const allEnabledDeviceIndices: number[] = [];
  const badEnabledDeviceIndices: number[] = [];
  const disabledDeviceIndices: number[] = [];

  // Función reutilizable para pedir toda la información al backend
  const getAllDevices = useCallback((config: SemafixConfig) => {
    // GET /data invoca a una función definida en semaphore-backend/main.go,
    // que obtiene todos los usuarios y todos datos de la tabla status_data en
    // la base de datos, junto con un tiempo (unix_time) indicando la fecha de
    // obtención de datos en segundos desde el epoch (busca "Unix Time")
    getHomeData()
    .then(res => {
      const { unixTime, users, statusData } = res.data;
      const newAllDevices = generateDeviceData(unixTime, users, statusData, config);
      setServerStatus({ unixTime: unixTime, isLoading: false, hasError: false });
      setAllDevices(newAllDevices);
    })
    .catch((err: AxiosError) => {
      console.log(err);
      setServerStatus({ ...serverStatus, isLoading: false, hasError: false });
    });
  }, []);

  useEffect(() => {
    var configResponse = {version: "", config: DEFAULT_CONFIG};
    getSemafixConfig()
    .then(res => configResponse = res.data)
    .catch(() => configResponse = {version: "?", config: DEFAULT_CONFIG})
    .finally(() => {
      setSoftwareVersion(configResponse.version);
      setConfig(configResponse.config);
      getAllDevices(configResponse.config);
    });
  }, [getAllDevices]);

  useEffect(() => {
    // Cada 30 segundos, volver a pedir todos los dispositivos
    const interval = setInterval(() => getAllDevices(config), 30000);
    return () => clearInterval(interval);
  }, [config, getAllDevices]);

  const reloadDeviceIndices = (updateReloadKey: boolean) => {
    badEnabledDeviceIndices.splice(0, badEnabledDeviceIndices.length);

    allDevices.forEach((dev, i) => {
      if (dev.enableMonitorAlarms) {
        allEnabledDeviceIndices.push(i);
        // Para la sección de dispositivos caídos (en DeviceSection), obtener todos
        // los dispositivos que tengan algún color amarillo o rojo
        if (dev.boxBGColor.startsWith("yellow") ||
          dev.boxBGColor.startsWith("red") ||
          dev.subBoxBGColor.startsWith("yellow") ||
          dev.subBoxBGColor.startsWith("red")
        ) {
          badEnabledDeviceIndices.push(i);
        }
      } else {
        disabledDeviceIndices.push(i);
      }
    });

    // Ordenarlos de mayor a menor retardo
    badEnabledDeviceIndices.sort((i, j) => {
      const d1 = allDevices[i];
      const d2 = allDevices[j];
      return (d2.delay - d1.delay) + 0.5*(d2.clientID - d1.clientID);
    });
    
    if (updateReloadKey) setReloadKey(reloadKey + 1);
  };

  reloadDeviceIndices(false);

  if (Object.keys(config).length === 0) {
    return (
      <Center height={window.innerHeight}>
        <VStack spacing={8}>
          <CircularProgress isIndeterminate size={150} color="teal" />
          <Text fontSize="2xl">Cargando datos...</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <Box
      key={reloadKey}
      w="full"
      px={2}
      py={{"base": 4, "lg": 6}}
    >
      <VStack spacing={{"base": 3, "md": 4}} align="center">
        <Header
          serverStatus={serverStatus}
          numEnabledDevices={allEnabledDeviceIndices.length}
          numBadDevices={badEnabledDeviceIndices.length}
          softwareVersion={softwareVersion}
          config={config}
          setConfig={setConfig}
          setAdminState={setAdminState}
        />
        <DeviceSection
          allDevices={allDevices}
          allEnabledDeviceIndices={allEnabledDeviceIndices}
          badEnabledDeviceIndices={badEnabledDeviceIndices}
          disabledDeviceIndices={disabledDeviceIndices}
          reloadDeviceIndices={reloadDeviceIndices}
        />
      </VStack>
    </Box>
  );
}