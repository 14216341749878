import React from "react";
import { Box, Button, Center, Heading, HStack, Image, SimpleGrid, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import ConfigFormModal from "./configFormModal/ConfigFormModal";
import { AdminState, SemafixConfig, ServerStatus } from "../../utils/interfaces";

import atmosfixLogo from "../../assets/atmosfix_logo.png";
import ServerClock from "./serverClock/ServerClock";
import { storeJWT } from "../../utils/jwt";


interface LegendBoxParams {
  prefix: string;
  time: "yellowTime" | "redTime" | "cyanTime";
  color: string;
}

interface HeaderParams {
  serverStatus: ServerStatus;
  numEnabledDevices: number;
  numBadDevices: number;
  softwareVersion: string;
  config: SemafixConfig;
  setConfig: (newConfig: React.SetStateAction<SemafixConfig>) => void;
  setAdminState: (newState: React.SetStateAction<AdminState>) => void;
};

/**
 * Header de la página web.
 */
export default function Header({
  serverStatus,
  numEnabledDevices,
  numBadDevices,
  softwareVersion,
  config,
  setConfig,
  setAdminState,
}: HeaderParams) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function createColorTimeString(keyPrefix: "yellowTime" | "redTime" | "cyanTime") {
    const days = config[(keyPrefix + "Days") as keyof SemafixConfig];
    const hours = config[(keyPrefix + "Hours") as keyof SemafixConfig];
    const minutes = config[(keyPrefix + "Minutes") as keyof SemafixConfig];
    const seconds = config[(keyPrefix + "Seconds") as keyof SemafixConfig];

    if (days !== 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
    if (hours !== 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    if (minutes !== 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  }

  function LegendBox({ prefix, time, color }: LegendBoxParams): JSX.Element {
    return (
      <Center w={{"base": "180px", "sm": "200px", "md": "240px", "lg": "220px"}} py="1" borderRadius="md" bg={`${color}.500`} color="white" shadow="sm">
        {`${prefix} ${createColorTimeString(time)}`}
      </Center>
    )
  }

  function logout() {
    storeJWT("");
    setAdminState({ isValidating: false, admin: null });
  }

  return (
    <VStack w="full" spacing={3}>
      {/*
      Título, logo y botón de cerrar sesión
      */}
      {/* Versión pequeña */}
      <VStack spacing={8} display={{"base": "block", "lg": "none"}}>
        <HStack spacing={{"base": 6, "sm": 10}} alignContent="center">
          <a href="https://happyvolt.com/atmosfix/" target="_blank" rel="noopener">
            <Image w="220px" src={atmosfixLogo} />
          </a>
          <Button colorScheme="red" onClick={logout} shadow="lg">Cerrar sesión</Button>
        </HStack>
        <Heading textAlign="center" as="h1" size="lg">
          HV Semáforo v{softwareVersion}
        </Heading> 
      </VStack>
      {/* Versión grande */}
      <HStack spacing={8} alignItems="center" display={{"base": "none", "lg": "flex"}}>
        <a href="https://happyvolt.com/atmosfix/" target="_blank" rel="noopener">
          <Image w="260px" src={atmosfixLogo} />
        </a>
        <Heading textAlign="center" as="h1" size="lg">
          HV Semáforo v{softwareVersion}
        </Heading>
        <Button colorScheme="red" onClick={logout} shadow="lg">Cerrar sesión</Button>
      </HStack>

      {/*
      Leyenda: los colores que indican cada estado de las DeviceBoxes

      Se leen los valores de config para indicar qué mostrar en cada caja

      Ejemplo: si redTimeDays === 1, redTimeHours === 12, redTimeMinutes === 30
      y redTimeSeconds === 0, entonces en la tercera caja (color rojo) se
      muestra "Retardo > 1d 12h 30m 0s": ese es el tiempo para que un
      dispositivo sea marcado como "rojo"
      */}
      <SimpleGrid columns={{"base": 2, "lg": 4}} rowGap={{"base": 1, "md": 2}} columnGap={{"base": 1, "sm": 3, "lg": 4}}>
        <LegendBox prefix="Retardo <" time="yellowTime" color="green" />
        <LegendBox prefix="Retardo >" time="yellowTime" color="yellow" />
        <LegendBox prefix="Retardo >" time="redTime" color="red" />
        <LegendBox prefix="Adelanto >" time="cyanTime" color="cyan" />
      </SimpleGrid>
      
      {/*
      Información del servidor
      Acá se muestra la hora (serverTime) y si hubo error (hasError)
      */}
      <HStack spacing={{"base": 1, "sm": 3, "lg": 10}}>
        <ServerClock serverStatus={serverStatus} />
        <Button px={{"base": 2, "sm": 4}} onClick={onOpen} leftIcon={<SettingsIcon />} shadow="lg">
          Configuración
        </Button>
        <ConfigFormModal isOpen={isOpen} onClose={onClose} config={config} setConfig={setConfig} />
      </HStack>

      {/*
      Información general de dispositivos
      Se muestra la cantidad de dispositivos conectados (numDevices - numBadDevices)
      y la cantidad de dispositivos rojos y amarillos (numBadDevices)
      */}
      <SimpleGrid columns={{"base": 1, "md": 2}} spacing={{"base": 2, "md": 3, "lg": "114px"}}>
        {/* Dispositivos conectados */}
        <HStack spacing="0" align="center" shadow="sm">
          <Box px={{"base": 6, "sm": 9, "md": 4, "lg": 12}} py={1} borderRadius="md" bg="green.500" color="white">
            <Text fontSize="xl"><b>Dispositivos conectados:</b></Text>
          </Box>
          <Text pl={4} fontSize="xl"><b>{numEnabledDevices - numBadDevices}</b></Text>
        </HStack>

        {/* Dispositivos desconectados */}
        <HStack spacing="0" align="center" shadow="sm">
          <Box pl={{ "base": 2, "sm": 5, "md": 4, "lg": 8 }} pr="0" py="1" borderLeftRadius="md" bg="yellow.500" color="white">
            <Text fontSize="xl"><b>Dispositi</b></Text>
          </Box>
          <Box px="0" py="1" bg="red.500" color="white">
            <Text fontSize="xl"><b>vos descon</b></Text>
          </Box>
          <Box pl="0" pr={{ "base": 2, "sm": 5, "md": 4, "lg": 8 }} py="1" borderRightRadius="md" bg="gray.500" color="white">
            <Text fontSize="xl"><b>ectados:</b></Text>
          </Box>
          <Text pl={4} fontSize="xl"><b>{numBadDevices}</b></Text>
        </HStack>
      </SimpleGrid>
      
    </VStack>
  )
}