import { Device, SemafixConfig, StatusRecord, User } from "../utils/interfaces";
import unixTimeToDateTime from "../utils/unixTimeToDateTime";

/**
 * generateDeviceData procesa statusData, un arreglo de datos por usuario.
 * Por cada usuario, genera los datos que se deben mostrar en la página:
 * el color de las cajas (DeviceBox), y qué texto deben mostrar. Toda esa
 * información se guarda en un nuevo arreglo de objetos, newAllDevices.
 */
export default function generateDeviceData(unixTime: number, users: User[], statusData: StatusRecord[], config: SemafixConfig): Device[] {
  const newAllDevices: Device[] = users.map(user => {
    const statusDataFilter = statusData.filter(s => s.username === user.username);
    if (statusDataFilter.length === 0) {
      // Esto no está porque sí, ya ha pasado por motivos obscuros y mejor
      // prevenir que lamentar.
      // Si no rellenamos con esto, el código en HomeScreen que busca si el
      // color parte con "yellow" o "red", puede explotar
      return {
        clientID: user.id,
        username: user.username,
        delay: 0,
  
        boxBGColor: "gray.500",
        boxHeading: `${user.id}. ${user.username.toUpperCase()}`,
        boxSubHeading: user.displayName,
        boxTime: "Sin información",
  
        subBoxBGColor: "gray.400",
        subBoxTextColor: "black",
        subBoxText: "Sin información",

        enableMonitorAlarms: false,
      };
    }

    const clientData = statusDataFilter[0];

    // Retardo (qué tan atrasada está la última fecha del software, respecto de
    // la fecha del servidor o la fecha en que se envió el dato)
    // En base a este retardo se asigna un color a DeviceBox
    const delay = unixTime - clientData.lastSoftwareUnixTime;

    /*
    Estilo de DeviceBox
    
    Adelanto > 10m    (o cyanTime):    Celeste
    Retardo  < 10m 0s (o yellowTime):  Verde
    Retardo  > 10m 0s (o yellowTime):  Amarillo
    Retardo  > 24h 0m (o redTime):     Rojo
    */
    var boxBGColor = "";
    if (Object.keys(config).length === 0) {
      boxBGColor = "gray.500";
    } else {
      const yellowTime = 24*60*60*config.yellowTimeDays + 60*60*config.yellowTimeHours + 60*config.yellowTimeMinutes + config.yellowTimeSeconds;
      const redTime = 24*60*60*config.redTimeDays + 60*60*config.redTimeHours + 60*config.redTimeMinutes + config.redTimeSeconds;
      const cyanTime = 24*60*60*config.cyanTimeDays + 60*60*config.cyanTimeHours + 60*config.cyanTimeMinutes + config.cyanTimeSeconds;
  
      // Adelanto > 10m
      if (delay < -cyanTime) {
        boxBGColor = "cyan.500";
      }
      // Retardo < 10m 0s
      else if (delay < yellowTime) {
        boxBGColor = "green.500";
      }
      // Retardo > 10m 0s (o sea, < 24h 0m)
      else if (delay < redTime) {
        boxBGColor = "yellow.500";
      }
      // Retardo > 24h 0m
      else {
        boxBGColor = "red.500";
      }
    }

    /*
    Contenido de DeviceBox

    Ejemplo:

    4. HVAC               (boxHeading)
    HappyVolt             (boxSubHeading)
    Última fecha válida:
    2/3/2023, 15:10:59    (boxTime)
    */
    const boxHeading = `${user.id}. ${user.username.toUpperCase()}`
    const boxSubHeading = user.displayName;
    const boxTime = unixTimeToDateTime(clientData.lastSoftwareUnixTime);

    /*
    Estilo y contenido de DeviceSubBox
    
    Estado == "Operando":  Verde    | Mostrar estado
    Estado != "Operando":  Amarillo | Mostrar estado
    ALERTA:                Rojo     | MOSTRAR ALERTA
    */
    var subBoxBGColor = "";
    var subBoxTextColor = "";
    var subBoxText = "";

    // ALERTA
    if (clientData.warning !== "") {
      subBoxBGColor = "red.400";
      subBoxTextColor = "white";
      subBoxText = clientData.warning;
    }
    // Estado == "Operando"
    else if (clientData.deviceStatus.toLowerCase() === "operando" || clientData.deviceStatus.toLowerCase() === "working") {
      subBoxBGColor = "green.400";
      subBoxTextColor = "white";
      subBoxText = clientData.deviceStatus;
    }
    // Estado != "Operando"
    else {
      subBoxBGColor = "yellow.400";
      subBoxTextColor = "black";
      subBoxText = clientData.deviceStatus;
    }

    // Objeto con todos los datos del dispositivo a mostrar en la página
    return {
      clientID: user.id,
      username: user.username,
      delay: delay,

      boxBGColor: boxBGColor,
      boxHeading: boxHeading,
      boxSubHeading: boxSubHeading,
      boxTime: boxTime,

      subBoxBGColor: subBoxBGColor,
      subBoxTextColor: subBoxTextColor,
      subBoxText: subBoxText,

      enableMonitorAlarms: clientData.enableMonitorAlarms,
    }
  });

  return newAllDevices;
}