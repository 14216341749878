import axios from "axios";
import { GETConfigResponseBody, GETDataResponseBody, SemafixConfig, TokenResponseBody } from "./interfaces";
import { readJWT } from "./jwt";


const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

function authorization() {
	return { headers: { Authorization: `Bearer ${readJWT()}` } };
}

// App
export function refreshToken() {
	return axiosClient.get<TokenResponseBody>(`/refresh_token`, authorization());
}

// Página Login
export function login(username: string, password: string) {
	return axiosClient.post<TokenResponseBody>(
		`/login`,
		{ username: username, password: password },
	);
}

// Página Home
export function getHomeData() {
	return axiosClient.get<GETDataResponseBody>(`/data`, authorization());
}

export function getSemafixConfig() {
	return axiosClient.get<GETConfigResponseBody>(`/config`, authorization());
}

export function postSemafixConfig(newConfig: SemafixConfig) {
	return axiosClient.post(
		`/config`,
		newConfig,
		authorization(),
	);
}

export function postSetAlarms(username: string, enableMonitorAlarms: boolean) {
	return axiosClient.post(
		`/setAlarms`,
		{ username: username, enableMonitorAlarms: enableMonitorAlarms },
		authorization(),
	);
}