import { RefObject, useRef } from "react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, AlertDialogOverlay, Box, Button, Center, Heading, IconButton, SimpleGrid, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { BellIcon, MoonIcon } from "@chakra-ui/icons";

import { postSetAlarms } from "../../utils/api";
import { Device } from "../../utils/interfaces";


interface DeviceSectionParams {
  allDevices: Device[];
  allEnabledDeviceIndices: number[];
  badEnabledDeviceIndices: number[];
  disabledDeviceIndices: number[];
  reloadDeviceIndices: (updateReloadKey: boolean) => void;
}

interface DeviceBoxParams {
  deviceData: Device;
  setAlarms: () => Promise<void>;
}

/**
 * DeviceSection muestra primero la información de todos los dispositivos
 * caídos (del arreglo badDevices), y luego la información de todos los
 * dispositivos, buenos o caídos (del arreglo allDevices).
 * 
 * Ambos arreglos, allDevices y badDevices, son arreglos de objetos con
 * la información de cada dispositivo. Por cada objeto en los arreglos,
 * se despliega una DeviceBox en la página con su información.
 */
export default function DeviceSection({
  allDevices,
  allEnabledDeviceIndices,
  badEnabledDeviceIndices,
  disabledDeviceIndices,
  reloadDeviceIndices,
}: DeviceSectionParams): JSX.Element {
  const makeBoxes = (deviceIndices: number[]) =>{
    return deviceIndices.map(i => {
      const deviceData = allDevices[i];
      const { username, enableMonitorAlarms: enableAlarms } = deviceData;

      const setAlarms = async () => {
        return postSetAlarms(username, !enableAlarms)
          .then(() => {
            allDevices[i].enableMonitorAlarms = !enableAlarms;
            reloadDeviceIndices(true);
          });
      };

      return <DeviceBox deviceData={deviceData} key={i} setAlarms={setAlarms} />;
    });
  };

  const pairs: [number[], string][] = [
    [badEnabledDeviceIndices, "Dispositivos con alertas"],
    [allEnabledDeviceIndices, "Todos los dispositivos habilitados"],
    [disabledDeviceIndices, "Dispositivos deshabilitados"],
  ];
  const content: JSX.Element[] = [];
  pairs.forEach(([deviceIndices, description]) => {
    content.push(
      <Heading alignContent="center" size={{ "base": "lg", "lg": "xl" }}>
        {description}: {deviceIndices.length}
      </Heading>
    );
    content.push(
      <SimpleGrid
        columns={{ "base": 1, "sm": 2, "md": 3, "lg": 4, "xl": 5 }}
        rowGap={{ "base": 2, "xl": 4 }}
        columnGap={{ "base": 2, "sm": 3, "xl": 5, "2xl": 8 }}
        >
        {makeBoxes(deviceIndices)}
      </SimpleGrid>
    );
  })

  return <>{content}</>;
}

/**
 * DeviceBox muestra toda la información relevante de un dispositivo. Cambia
 * de color dependiendo de su estado: más información en el archivo
 * homeScreen/generateDeviceData.js.
 */
function DeviceBox({ deviceData, setAlarms }: DeviceBoxParams): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as RefObject<HTMLButtonElement>;

  const { username, boxBGColor, boxHeading, boxSubHeading, boxTime, subBoxBGColor, subBoxTextColor, subBoxText, enableMonitorAlarms } = deviceData;
  const link = `https://atmosfix.happyvolt.com/?username=${username}`;
  var newBoxBGColor = boxBGColor;
  var newSubBoxBGColor = subBoxBGColor;
  if (!enableMonitorAlarms) {
    newBoxBGColor = (
      boxBGColor.substring(0, boxBGColor.length - 3) +
      (boxBGColor.startsWith("gray") ? "200" : "100")
    );
    newSubBoxBGColor = (
      subBoxBGColor.substring(0, subBoxBGColor.length - 3) +
      (subBoxBGColor.startsWith("gray") ? "100" : "50")
    );
  }

  return (
    <VStack
      w={{ "base": "250px", "sm": "210px", "md": "218px", "xl": "226px", "2xl": "270px" }}
      spacing={0}
      shadow="lg"
    >
      <Box
        position="relative"
        w="full"
        pt={{ "base": 1, "2xl": 2 }}
        pb={{ "base": 2, "2xl": 3 }}
        borderTopRadius="lg"
        bg={newBoxBGColor}
        color={enableMonitorAlarms ? "white" : "gray.500"}
      >
        <IconButton
          icon={enableMonitorAlarms ? <MoonIcon /> : <BellIcon />}
          aria-label={enableMonitorAlarms ? "Desactivar alarma" : "Activar alarma"}
          position="absolute"
          top={{ "base": 3, "2xl": 4 }}
          left={{ "base": 3, "2xl": 5 }}
          size="xs"
          colorScheme={enableMonitorAlarms ? "blue" : "yellow"}
          onClick={onOpen}
          border="1px"
          shadow="md"
        />
        <Heading textAlign="center" as="h3" size="lg" pt={1} noOfLines={1}>
          <a href={link}>{boxHeading}</a>
        </Heading>
        <Heading mt={-1} textAlign="center" as="h4" size="md" pb={1} noOfLines={1}>
          <a href={link}>{boxSubHeading}</a>
        </Heading>
        <Text lineHeight={1.2} align="center">
          Última comunicación:<br />{boxTime}
        </Text>
      </Box>

      {/* DeviceSubBox */}
      <Center
        px={4}
        w="full"
        h="52px"
        borderBottomRadius="lg"
        bg={newSubBoxBGColor}
        color={enableMonitorAlarms ? subBoxTextColor : "gray.400"}
      >
        <Text noOfLines={2} lineHeight={1.2} align="center">
          {subBoxText}
        </Text>
      </Center>

      {/* AlertDialog */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {enableMonitorAlarms ? "Desactivar" : "Activar"} {boxHeading}
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Seguro que deseas {enableMonitorAlarms && "des"}activar el dispositivo <b>{boxHeading} ({boxSubHeading})</b>?<br/>
              <i>Esto solo afectará a los correos enviados a los monitores: no afecta la configuración de correos a usuarios.</i>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme={enableMonitorAlarms ? "blue" : "yellow"} onClick={() => setAlarms().then(onClose)} ml={3}>
                {enableMonitorAlarms ? <MoonIcon mr={2} /> : <BellIcon mr={2} />}
                {enableMonitorAlarms ? "Desactivar" : "Activar"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </VStack>
  )
}