import React from "react";
import {
  Box,
  Heading, Text,
  HStack, VStack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  Button,
  Select,
  Radio, RadioGroup,
  Input,
} from "@chakra-ui/react";

import { postSemafixConfig } from "../../../utils/api";
import { SemafixConfig } from "../../../utils/interfaces";


interface ConfigFormModalParams {
  isOpen: boolean;
  onClose: () => void;
  config: SemafixConfig;
  setConfig: (newConfig: React.SetStateAction<SemafixConfig>) => void;
}

export default function ConfigFormModal({ isOpen, onClose, config, setConfig }: ConfigFormModalParams): JSX.Element {
  var localConfig = JSON.parse(JSON.stringify(config));

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    localConfig.mailRecipients = localConfig.mailRecipients.replaceAll(" ", "");

    /**
     * POST /semaphore/config llama a la función POSTConfig, definida en
     * semaphore-backend/afapi/post_config.go
     */
    postSemafixConfig(localConfig)
    .then(_ => {
      config = JSON.parse(JSON.stringify(localConfig));
      setConfig(localConfig);
      onClose();
    })
  }

  // Función necesaria para desactivar el scrolling en los campos numéricos
  // Por defecto el scrolling cambia el valor en esos campos, lo cual es molesto
  // Esta función desactiva eso
  function disableScroll(event: React.WheelEvent<HTMLInputElement>) {
    (event.target as HTMLElement).blur();
  }
 
  /* 
  Nota para todas las funciones onChange:
  event.target se refiere al input en sí (Input, TimeInput, Radio)
  event.target.name es el parámetro name pasado al input
  event.target.value es el valor actual almacenado por el input
  En el caso de Select, se debe usar event.target.selectedOptions para acceder
  a las opciones disponibles a seleccionar
  */

  function onChangeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    localConfig[event.target.name] = event.target.selectedOptions[0].value === "true";
  }

  function onChangeInt(event: React.ChangeEvent<HTMLInputElement>) {
    // event.target se refiere al input en sí (Input, TimeInput, Radio)
    localConfig[event.target.name] = parseInt(event.target.value);
  }

  function onChangeBoolean(event: React.ChangeEvent<HTMLInputElement>) {
    // event.target se refiere al input en sí (Input, TimeInput, Radio)
    localConfig[event.target.name] = event.target.value === "true";
  }

  function onChangeString(event: React.ChangeEvent<HTMLInputElement>) {
    // event.target se refiere al input en sí (Input, TimeInput, Radio)
    localConfig[event.target.name] = event.target.value;
  }

  /* Funciones auxiliares para evitar repetir tanto código */

  function MyHeading({ heading }: { heading: string }) {
    return <Heading as="h3" size="md" py={4}>{heading}</Heading>;
  }

  function MyLabel({ label }: { label: string }) {
    return <Box w="132px"><p><b>{label}</b></p></Box>;
  }

  function TimeInput({ label, namePrefix }: { label: string; namePrefix: string }) {
    function UnitField({ unit }: { unit: string }) {
      // Asumiendo que namePrefix === "yellowTime":
      // Ejemplo con unit === "hours": se asigna initial === "h",
      // name === "yellowTimeHours", placeholder === "hh" y max === 23
      var initial = unit.charAt(0);
      var name = namePrefix + initial.toUpperCase() + unit.slice(1);
      var placeholder = initial + initial;
      var max = (unit === "days") ? 99 : ((unit === "hours") ? 23 : 59);
      return (
        <HStack spacing={0}>
          <Input
            name={name} defaultValue={localConfig[name]} placeholder={placeholder}
            min={0} max={max}
            onChange={onChangeInt} onWheel={disableScroll}
            type="number" variant="flushed" maxW={5}
          />
          <Text>{initial}</Text>
        </HStack>
      );
    }

    return (
      <HStack>
        <MyLabel label={label} />
        <HStack spacing={6}>
          <UnitField unit="days" />
          <UnitField unit="hours" />
          <UnitField unit="minutes" />
          <UnitField unit="seconds" />
        </HStack>
      </HStack>
    );
  }

  return (
    <Modal size="xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configuración del semáforo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={onSubmit} method="post">
            <VStack align="left" spacing={1}>
              {/* Tiempos */}
              <MyHeading heading="Tiempos" />
              <TimeInput label="Amarillo" namePrefix="yellowTime" />
              <TimeInput label="Rojo" namePrefix="redTime" />
              <TimeInput label="Celeste" namePrefix="cyanTime" /> 

              {/* Correos */}
              <MyHeading heading="Correos" />
              <HStack>
                <MyLabel label="Enviar correos de conexión" />
                <RadioGroup name="mailEnableOnConnectionChange" defaultValue={localConfig.mailEnableOnConnectionChange.toString()}>
                  <HStack spacing={4}>
                    <Radio value="true" onChange={onChangeBoolean}>Sí</Radio>
                    <Radio value="false" onChange={onChangeBoolean}>No</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              <HStack>
                <MyLabel label="Enviar correos de alerta" />
                <RadioGroup name="mailEnableOnAlertChange" defaultValue={localConfig.mailEnableOnAlertChange.toString()}>
                  <HStack spacing={4}>
                    <Radio value="true" onChange={onChangeBoolean}>Sí</Radio>
                    <Radio value="false" onChange={onChangeBoolean}>No</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              <HStack>
                <MyLabel label="Destinatarios" />
                <Input name="mailRecipients" defaultValue={localConfig.mailRecipients} onChange={onChangeString} placeholder="Correos separados por punto y coma (;)" htmlSize={40} width="auto" />
              </HStack>
              <HStack> 
                <MyLabel label="Método de envío" />
                <Select name="useSendGrid" defaultValue={localConfig.useSendGrid.toString()} onChange={onChangeSelect} variant="flushed" width="auto" >
                  <option value="false">Correo directo desde Desarrollo</option>
                  <option value="true">Monitor SendGrid</option>
                </Select>
              </HStack>
              <HStack pb="4">
                <MyLabel label="[DEBUG] Desactivar correos a clientes" />
                <RadioGroup name="mailDebugMode" defaultValue={localConfig.mailDebugMode.toString()}>
                  <HStack spacing={4}>
                    <Radio value="true" onChange={onChangeBoolean}>Sí</Radio>
                    <Radio value="false" onChange={onChangeBoolean}>No</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              
              <Button type="submit" shadow="lg">Actualizar</Button>
            </VStack>
            
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

